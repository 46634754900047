<template>
    <div class="department-index">
        <navigationBar @buttonClickEmit0="addOrEditDept" :buttonList="buttonList" />

        <div class="department-tree">
            <el-tree
                :data="org_list"
                node-key="id"
                :props="defaultProps"
                style="margin-top:10px;"
                class="filter-tree"
            >
                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{ node.label }}</span>
                    <span class="tree-button">
                        <span v-if="data.status == 0 " style="color: #67C23A">启用</span>
                        <span v-if="data.status == 1 " style="color:#FF0000">停用</span>
                        <el-button type="danger" icon="el-icon-delete" circle size="mini"></el-button>
                        <el-button
                            type="primary"
                            icon="el-icon-edit"
                            circle
                            size="mini"
                            v-on:click.stop="addOrEditDept(node,data)"
                        ></el-button>
                    </span>
                </span>
            </el-tree>
        </div>

        <el-dialog :title="titleOrg" :visible.sync="dialogFormVisible" width="40%">
            <el-form ref="org_form" :model="org_form" label-width="140px">
                <el-form-item label="组织编码" prop="org_code">
                    <el-input v-model="org_form.org_code" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="组织名称" prop="org_name">
                    <el-input v-model="org_form.org_name" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="上级组织" prop="parent_org_id">
                    <el-cascader
                        v-model="org_form.parent_org_id"
                        placeholder="请选择上级组织"
                        :options="this.org_list"
                        :props="defaultProps"
                        filterable
                        @change="change"
                        clearable
                        style="width:80%"
                    ></el-cascader>
                </el-form-item>

                <el-form-item label="组织类型" prop="org_name">
                    <template v-for="item in org_type_list">
                        <el-radio-group v-model="org_form.org_type" style="margin-right: 15px">
                            <el-radio :label="item.dict_key">{{item.dict_value}}</el-radio>
                            <!-- {{item}} -->
                        </el-radio-group>
                        <!-- <el-radio v-model="org_form.org_type" label="item.dict_key" >{{item.dict_value}}</el-radio> -->
                    </template>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="org_form.remark" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="组织简介" prop="org_info">
                    <el-input v-model="org_form.org_info" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <template v-for="item in org_state_list">
                        <el-radio-group v-model="org_form.status" style="margin-right: 15px">
                            <el-radio :label="item.dict_key">{{item.dict_value}}</el-radio>
                        </el-radio-group>
                        <!-- <el-input v-model="org_form.status" style="width: 80%;"></el-input> -->
                    </template>
                </el-form-item>
                <!---->
                <!--<el-form-item label="部门名称" prop="dept_name">-->
                <!--<el-input v-model="addForm.dept_name" style="width: 80%;"></el-input>-->
                <!--</el-form-item>-->
                <!--<el-form-item label="层级" prop="level">-->
                <!--<el-input v-model.number="addForm.level" style="width: 80%;"></el-input>-->
                <!--</el-form-item>-->
                <!--<el-form-item prop = "district" label="所属区域：">-->
                <!--<el-select v-model="addForm.district" placeholder="所属区域："style="width: 80%;">-->
                <!--<el-option-->
                <!--v-for="item in districtInfo"-->
                <!--:key="item.dictKey"-->
                <!--:label="item.dictValue"-->
                <!--:value="item.dictKey">-->
                <!--</el-option>-->
                <!--</el-select>-->
                <!--</el-form-item>-->
                <!--<el-form-item label="可用状态" prop="status">-->
                <!--<el-radio-group v-model="addForm.status">-->
                <!--<el-radio :label="1">可用</el-radio>-->
                <!--<el-radio :label="2">不可用</el-radio>-->
                <!--</el-radio-group>-->
                <!--</el-form-item>-->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="submitForm">提 交</el-button>
                <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import navigationBar from "@/components/navigationBar";
import {
    getOrganizationInfoList,
    getDictionaryListByCode,
    addOrEditOrganization,
} from "@/api/getUcenterData";
import { getSysDictionaryList } from "@/api/getBaseData";
export default {
    data() {
        return {
            buttonList: [
                {
                    backgroundColor: "#52D1AF",
                    icon: "/static/img/add.png",
                    text: "新增",
                },
            ],
            // 组织的集合
            org_list: [],
            // 树形组件的映射
            defaultProps: {
                checkStrictly: true,
                children: "children_list",
                label: "org_name",
                value: "id",
            },

            // 编辑组织的表
            org_form: {
                id: "",
                org_code: "",
                org_name: "",
                parent_org_id: "",
                org_type: "",
                remark: "",
                org_info: "",
                status: "",
            },

            titleOrg: "编辑、添加组织",

            dialogFormVisible: false,

            //组织类型列表
            org_type_list: [],

            // 组织状态列表
            org_state_list: [],
        };
    },
    components: {
        navigationBar,
    },
    created() {},
    mounted() {
        this.getOrganizationInfoList();

        // 获取字典列表
        let para = {
            classifyCode: "org_type",
        };
        getSysDictionaryList(para).then((result) => {
            this.org_type_list = result.data;
            console.log("企业类型：", this.org_type_list);
        });

        // 获取表单状态列表
        let para2 = {
            classifyCode: "org_status",
        };
        getSysDictionaryList(para2).then((result) => {
            this.org_state_list = result.data;
        });
    },
    methods: {
        getOrganizationInfoList() {
            let para = {};
            getOrganizationInfoList(para).then((result) => {
                this.org_list = result.data;
                console.log("得出结果：", this.org_list);
            });
        },

        //部门信息弹出窗口
        addOrEditDept(node, data) {
            if (data == null) {
                console.log("是新增");
            } else if (data.id != null) {
                console.log("是编辑");
            }

            this.dialogFormVisible = true;

            if (data != null) {
                this.org_form = data;
            }
        },
        // 级联选择器选值改变时触发的函数
        change: function (value) {
            let org_id = value[value.length - 1];
            console.log("级联选择器选中的id：", org_id);
            this.org_form.parent_org_id = org_id;
        },

        // 表单提交
        submitForm() {
            this.$confirm("确认提交吗？", "提示", {}).then(() => {
                let para = {
                    id: this.org_form.id,
                    org_code: this.org_form.org_code,
                    org_name: this.org_form.org_name,
                    org_type: this.org_form.org_type,
                    parent_org_id: this.org_form.parent_org_id,
                    org_info: this.org_form.org_info,
                    remark: this.org_form.remark,
                    status: this.org_form.status,
                };
                console.log("向后台提交的参数：", para);
                addOrEditOrganization(para).then((result) => {
                    if (result.resultCode == 1) {
                        this.$message({
                            message: result.resultMsg,
                            type: "success",
                        });
                    } else if (result.resultCode == -1001) {
                        this.$message({
                            message: result.resultMsg,
                            type: "error",
                        });
                    }
                });
                this.dialogFormVisible = false;
            });
        },
    },
};
</script>
<style lang="scss">
.department-index {
    .department-tree {
        margin-top: 10px;
        .el-tree-node__content {
            height: 40px;
        }
        .el-tree-node {
            border-bottom: 1px solid #eceef6;
        }
        .el-tree-node:last-child {
            border-bottom: 0px solid #eceef6;
        }
        .custom-tree-node {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            padding-right: 8px;
            .tree-button {
                float: right;
                display: inline-block;
                span:first-child {
                    margin-right: 100px;
                }
            }
        }
    }
    .el-dialog__header {
        background: #e9ecee;
        padding: 1rem;
    }
    .el-dialog__headerbtn {
        top: 1.25rem;
    }
    .el-dialog__title {
        font-weight: bold;
    }
}
</style>
